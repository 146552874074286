<template>
  <div :style="computedStyle" v-show="!isHidden">
    <span class="registry_fields_label">
      {{ label }}
      <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
        <i class="el-icon-question"></i>
      </el-tooltip>
    </span>
    <el-form-item
      :prop="name"
      :rules="[
      { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' },
      rules.controlValues
    ]">
    <el-input v-bind:value="value"
                  type="number"
                  step="any"
                  :placeholder="placeholder || $locale.main.placeholder.float_field"
                  :size="size"
                  v-on:input="$emit('input', ($event || null))" :name="name" :readonly="readonly"></el-input>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import registryMixin from '../registry/registry_mixins'
import controlValues from '../control_values_mixin'
export default {
  name: 'float_field',
  mixins: [mixin, registryMixin, controlValues],
  props: {
    controlValues: {
      type: Object,
      editor: 'ControlValues',
      description: 'Контроль значений',
      default: () => {
        return {
          operator: null,
          type: 'integer',
          conditions: []
        }
      },
      options: { type: 'integer' }
    },
    name: {
      type: String,
      frozen: true
    },
    label: {
      description: 'Название',
      type: String
    },
    placeholder: {
      description: 'Плейсхолдер',
      type: String
    },
    tooltip: {
      description: 'Подсказка',
      type: String
    },
    required: {
      description: 'Обязательное',
      type: Boolean,
      default: false
    }
  },
  computed: {
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  }
}
</script>

<style scoped>

</style>
